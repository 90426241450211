import React from "react";
import Layout from '../components/layout';
import * as textCss from "./modules/text.module.scss";
import {graphql, useStaticQuery} from "gatsby";
import {documentToReactComponents} from "@contentful/rich-text-react-renderer";

const VendrePage = () => {
    const datas = useStaticQuery(graphql`
        query {
          allContentfulDessinsAVendre {
            edges {
              node {
                titre
                texte {
                  raw
                }
              }
            }
          }
        }`
    );

    const data = datas.allContentfulDessinsAVendre.edges[0].node;
    const content = JSON.parse(data.texte.raw);

    return (
        <Layout>
            <div className={textCss.container}>
                <h1>{data.titre}</h1>
                <div>{documentToReactComponents(content)}</div>
            </div>
        </Layout>
    )
};

export default VendrePage;
